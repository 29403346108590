"use client";

import CartItemModal from "@/components/ui/ecommerce/cart/CartItemModal";
import RecommendedProduct from "@/components/ui/ecommerce/cart/RecommendedProduct";
import { CloseIcon } from "@/components/ui/Icons";
import useCartStore, { ProductInterface } from "@/stores/useCartStore";
import { getIp } from "@/utils/getCountryIp";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { Toaster } from "@/components/ui/toaster";
import { fetchProductsByParentCategoryId } from "@/libs/data";

interface CartModalProps {
  open: boolean;
  onClose: () => void;
}

export default function CartModal({ open, onClose }: CartModalProps) {
  const router = useRouter();
  const { cart } = useCartStore();
  const [mounted, setMounted] = useState(false);
  const [country, setCountry] = useState<string | null>(null);
  const [recommendedProducts, setRecommendedProducts] = useState<
    ProductInterface[]
  >([]);

  useEffect(() => {
    async function fetchCountry() {
      const countryCode = await getIp();
      setCountry(countryCode);
    }
    fetchCountry();
  }, []);

  useEffect(() => {
    async function fetchRecommendedProducts() {
      try {
        const allProducts = await fetchProductsByParentCategoryId("8");
        const cartItemIds = new Set(cart.map((item) => item.id));
        const filtered = allProducts.filter(
          (product: any) => !cartItemIds.has(product.id)
        );
        setRecommendedProducts(filtered.slice(0, 4));
      } catch (error) {
        //console.error("Error fetching recommended products:", error);
        setRecommendedProducts([]);
      }
    }
    fetchRecommendedProducts();
  }, [cart]);

  const isUSD = country !== "CL" && country !== null;

  useEffect(() => {
    setMounted(true);
  }, []);

  const cartTotal = cart.reduce((acc, item) => {
    const itemPrice: any = isUSD
      ? item.discounted_usd_price ?? item.usd_price
      : item.discounted_price ?? item.price;
    return acc + itemPrice * item.qty;
  }, 0);

  const itemCount = cart.reduce((acc, item) => acc + item.qty, 0);

  const formatPrice = (price: number) => {
    return price.toLocaleString(isUSD ? "en-US" : "es-CL", {
      style: "currency",
      currency: isUSD ? "USD" : "CLP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  if (!mounted) return null;

  return (
    <div
      className={`fixed right-0 sm:right-4 z-50 w-full sm:w-96 md:w-[420px] bg-gray-100 rounded-t-md shadow-lg transition-all duration-500 ease-in-out ${
        open ? "animate-slideIn bottom-0" : "animate-slideOut -bottom-full"
      }`}
    >
      <div className="flex justify-between items-center p-4 border-b">
        <h3 className="text-lg font-semibold text-black">
          CARRITO <span className="text-gray-400">({itemCount})</span>
        </h3>
        <button
          className="bg-gray-200 p-2 rounded-full"
          onClick={onClose}
          aria-label="Cerrar carrito"
        >
          <CloseIcon className="fill-gray-400 w-4 h-4" />
        </button>
      </div>
      <div className="overflow-y-auto max-h-[500px] p-2 sm:p-3 md:p-4 custom-scrollbar">
        {cart.length > 0 ? (
          cart.map((item) => (
            <CartItemModal
              key={`${item.id}-${item.variant?.id ?? ""}`}
              product={{
                ...item,
                usd_price: item.usd_price ?? 0,
                discounted_usd_price: item.discounted_usd_price,
              }}
            />
          ))
        ) : (
          <p className="text-center text-gray-500 py-4">
            Tu carrito está vacío
          </p>
        )}

        {cart.length > 0 && recommendedProducts.length > 0 && (
          <div className="mt-6">
            <h4 className="text-md font-semibold mb-2">
              Complementa tu compra
            </h4>
            <div className="space-y-2">
              {recommendedProducts.map((product) => (
                <RecommendedProduct key={product.id} product={product} />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between items-center p-4 border-t text-center gap-3">
        <small className="text-gray-500">
          Los gastos de envío se calculan en la página de pago.
        </small>
        <button
          className="w-full bg-bright-pink text-white p-3 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => {
            router.push("/payment");
            onClose();
          }}
          disabled={cart.length === 0}
        >
          IR A PAGAR ▪ {formatPrice(cartTotal)}
        </button>
      </div>
      <Toaster />
    </div>
  );
}

