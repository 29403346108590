"use client";
import CartModal from "@/components/ui/ecommerce/cart/CartModal";
import { CartIcon } from "@/components/ui/Icons";
import useCartStore from "@/stores/useCartStore";
import { usePathname } from "next/navigation";
import { useState } from "react";

export default function CartBtnFixed() {
  const { cart } = useCartStore();
  const [openCart, setOpenCart] = useState(false);
  const pathname = usePathname();

  const handleOpenCart = () => {
    setOpenCart(true);
  };

  const handleCloseCart = () => {
    setOpenCart(false);
  };

  if (pathname === "/payment") return null;

  if (cart.length === 0) return null;

  return (
    <>
      <div className="fixed bottom-6 right-6 z-50">
        <button
          className="relative bg-bright-pink text-white p-3 rounded-md"
          onClick={handleOpenCart}
        >
          <CartIcon className="fill-cream w-7 h-7" />
          <span className="absolute -top-2 -right-2 bg-orange text-xs font-semibold rounded-full px-2">
            {cart.length}
          </span>
        </button>
      </div>
      <CartModal open={openCart} onClose={handleCloseCart} />
    </>
  );
}
