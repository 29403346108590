import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import axios from "axios";
import { verifyAndDecodeToken } from "@/utils/jwt";
import axiosInstance, { updateAxiosToken } from "@/utils/axiosInstance";

interface IUser {
  id: string;
  email: string;
  username: string;
  status: boolean;
  role: number;
}

interface AuthState {
  userData: IUser | null;
  userMetadata: any;
  token: string;
  logout: () => Promise<void>;
  login: (token: any) => Promise<void>;
  reToken: () => Promise<void>;
  checkLoginStatus: () => Promise<boolean>;
  getTkn: () => Promise<string | null>;
  fetchTokenAndUserData: () => Promise<void>;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      userData: null,
      userMetadata: null,
      token: "",

      logout: async () => {
        set({ userData: null, userMetadata: null, token: "" });

        try {
          await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/auth/logout`,
            {},
            { withCredentials: true }
          );
        } catch (error) {
          console.error("Error during logout:", error);
          throw new Error("Error during logout");
        }
      },

      login: async (token: string) => {
        const { error, data } = await verifyAndDecodeToken(token);

        if (error) {
          throw new Error("Error verifying token");
        }
        set({ token });
        updateAxiosToken(token);

        try {
          const userMetadataRes = await axiosInstance.get(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/userdata/${data?.id}`,
            { withCredentials: true }
          );
          const userDataRes = await axiosInstance.get(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/${data?.id}`,
            { withCredentials: true }
          );

          set({
            userMetadata: userMetadataRes.data.userMeta,
            userData: userDataRes.data.user,
          });

          const userData = {
            email: data?.email,
            username: data?.username,
          };
        } catch (err: any) {
          console.warn("Error fetching user data:", err);
          throw new Error("Error fetching user data");
        }
      },

      reToken: async () => {
        const token = await get().getTkn();

        try {
          const res = await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/auth/retoken`,
            { token },
            { withCredentials: true }
          );
          await get().login(res.data.token);
        } catch (err: any) {
          console.error("Error during reToken:", err);
          throw new Error("Error during reToken");
        }
      },

      getTkn: async () => {
        try {
          const tokenRes = await axios.get(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/auth/token`,
            { withCredentials: true }
          );
          return tokenRes.data.token;
        } catch (err: any) {
          console.error("Error fetching token:", err);
          return null;
        }
      },

      checkLoginStatus: async () => {
        try {
          const tkn = await get().getTkn();
          if (!tkn) {
            return false;
          }
          const { error } = await verifyAndDecodeToken(tkn);
          return !error;
        } catch (err) {
          console.warn("Error checking login status:", err);
          return false;
        }
      },

      fetchTokenAndUserData: async () => {
        try {
          const tkn = await get().getTkn();

          if (!tkn) {
            console.warn("No autorizado: el usuario no ha iniciado sesión");
            return;
          }

          set({ token: tkn });
          const { data, error } = await verifyAndDecodeToken(tkn);

          if (error) {
            console.warn("Error verifying token:", error);
            return;
          }

          await get().reToken();

          try {
            const userMetadataRes = await axiosInstance.get(
              `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/userdata/${data?.id}`,
              { withCredentials: true }
            );
            const userDataRes = await axiosInstance.get(
              `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/${data?.id}`,
              { withCredentials: true }
            );

            set({
              userMetadata: userMetadataRes.data.userMeta,
              userData: userDataRes.data.user,
            });
          } catch (err: any) {
            console.warn("Error fetching user data:", err);
          }
        } catch (err: any) {
          console.warn("Error fetching token:", err);
        }
      },
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useAuthStore;
