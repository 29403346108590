import Image from "next/image";
import { useState, useEffect } from "react";
import { toast } from "@/components/ui/use-toast";
import useCartStore, {
  ProductInterface,
  VariantInfo,
} from "@/stores/useCartStore";

interface RecommendedProductProps {
  product: any;
}

interface Option {
  id: number;
  value: string;
  hex_code?: string;
}

interface Variation {
  id: number;
  name: string;
  options: Option[];
}

export default function RecommendedProduct({
  product,
}: RecommendedProductProps) {
  const { addToCart } = useCartStore();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<any | null>(null);
  const [showColorSelector, setShowColorSelector] = useState(false);

  const firstVariant =
    product.type === "variant" &&
    product.variants &&
    product.variants.length > 0
      ? product.variants[0]
      : null;

  const colorVariation: Variation | undefined =
    product.type === "variant" && product.variants
      ? {
          id: product.variants[0].option.variation.id,
          name: product.variants[0].option.variation.name,
          options: product.variants.map((v: any) => ({
            id: v.option.id,
            value: v.option.value,
            hex_code: v.option.hex_code,
          })),
        }
      : undefined;

  useEffect(() => {
    if (selectedOption && product.type === "variant" && product.variants) {
      const variant = product.variants.find(
        (v: any) => v.option.id === selectedOption.id
      );
      if (variant) {
        setSelectedVariant(variant);
      }
    }
  }, [selectedOption, product.variants]);

  const handleAddProduct = () => {
    const cartProduct: ProductInterface = {
      ...product,
      price: selectedVariant?.price ?? product.price,
      usd_price: selectedVariant?.usd_price ?? product.usd_price,
      discounted_price:
        selectedVariant?.discounted_price ?? product.discounted_price,
      discounted_usd_price:
        selectedVariant?.discounted_usd_price ?? product.discounted_usd_price,
      thumbnail_url: selectedVariant?.thumbnail_url ?? product.thumbnail_url,
      stock: selectedVariant?.stock ?? product.stock,
      sku: selectedVariant?.sku ?? product.sku,
      grams: selectedVariant?.grams ?? product.grams,
      variant_id: selectedVariant?.id ?? null,
    };

    const variantInfo: any | undefined = selectedVariant
      ? {
          id: selectedVariant.id,
          hex_code: selectedVariant.option.hex_code ?? null,
          value: selectedVariant.option.value,
          name: selectedVariant.option.variation.name,
        }
      : undefined;

    addToCart(cartProduct, 1, variantInfo);

    toast({
      title: "Producto añadido al carrito",
      image: cartProduct.thumbnail_url,
      description: `1 x ${product.name}${
        variantInfo ? ` (${variantInfo.name}: ${variantInfo.value})` : ""
      } añadido al carrito.`,
      duration: 3000,
    });

    setShowColorSelector(false);
    setSelectedOption(null);
    setSelectedVariant(null);
  };

  const formatPrice = (price: number) => {
    return price.toLocaleString("es-CL", {
      style: "currency",
      currency: "CLP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const currentVariant = selectedVariant || firstVariant;

  const displayPrice =
    product.type === "variant" && currentVariant
      ? currentVariant.discounted_price || currentVariant.price
      : product.discounted_price || product.price;

  const displayImage =
    product.type === "variant" && currentVariant
      ? currentVariant.thumbnail_url || product.thumbnail_url
      : product.thumbnail_url;

  const hasStock =
    product.type === "variant"
      ? product.variants.some((v: any) => v.stock > 0)
      : product.stock > 0;

  return (
    <div className="flex items-center justify-between p-2 border rounded-md">
      <div className="flex items-center space-x-2">
        <Image
          src={displayImage}
          alt={product.name}
          width={50}
          height={50}
          className="rounded-md object-cover"
        />
        <div>
          <h4 className="text-sm font-semibold text-black">{product.name}</h4>
          <p className="text-xs text-gray-500">
            {formatPrice(displayPrice || 0)}
          </p>
        </div>
      </div>

      {!showColorSelector ? (
        <button
          onClick={() =>
            product.type === "variant" && hasStock
              ? setShowColorSelector(true)
              : hasStock
              ? handleAddProduct()
              : null
          }
          className={`text-xs px-3 py-1.5 rounded-full transition-colors ${
            hasStock
              ? "bg-black text-white hover:bg-gray-800"
              : "bg-gray-300 text-gray-600 cursor-not-allowed"
          }`}
          disabled={!hasStock}
        >
          {hasStock ? "Agregar" : "Sin stock"}
        </button>
      ) : (
        <div className="flex flex-col items-end space-y-2">
          <div className="flex space-x-1">
            {colorVariation?.options.map((option) => {
              const variantStock =
                product.variants.find((v: any) => v.option.id === option.id)
                  ?.stock || 0;
              return (
                <button
                  key={option.id}
                  onClick={() => variantStock > 0 && setSelectedOption(option)}
                  className={`
                    w-6 h-6 rounded-full transition-all duration-200 ease-in-out
                    ${
                      selectedOption?.id === option.id
                        ? "ring-2 ring-black ring-offset-1"
                        : ""
                    }
                    ${variantStock === 0 ? "opacity-50 cursor-not-allowed" : ""}
                  `}
                  style={{
                    backgroundColor: option.hex_code,
                    boxShadow:
                      selectedOption?.id === option.id
                        ? "0 0 0 1px white, 0 0 0 2px black"
                        : "0 0 0 1px rgba(0,0,0,0.1) inset",
                  }}
                  aria-label={`Select color ${option.value}`}
                  disabled={variantStock === 0}
                />
              );
            })}
          </div>
          <button
            onClick={handleAddProduct}
            className={`text-xs px-3 py-1.5 rounded-full transition-colors ${
              selectedVariant && selectedVariant.stock > 0
                ? "bg-black text-white hover:bg-gray-800"
                : "bg-gray-300 text-gray-600 cursor-not-allowed"
            }`}
            disabled={!selectedVariant || selectedVariant.stock === 0}
          >
            {selectedVariant && selectedVariant.stock > 0
              ? "Añadir al carrito"
              : "Sin stock"}
          </button>
        </div>
      )}
    </div>
  );
}
