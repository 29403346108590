"use client";
import Image from "next/image";
import axiosInstance from "@/utils/axiosInstance";
import { useState, useEffect, use } from "react";
import useAuthStore from "@/stores/useAuthStore";

export default function UserImgExp(props: any) {
  const colorBase = "#924b79";
  const colorPorcentual = "#f6f2ea";
  const [experiencePercentage, setExperiencePercentage] = useState(0);
  const { userMetadata, userData } = useAuthStore();
  //console.log(userMetadata);

  useEffect(() => {
    if (userData?.id) {
      axiosInstance
        .get(
          `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/get-level/${userData?.id}`
        )
        .then((response) => {
          setExperiencePercentage(response.data.xpPercentage);
        });
    }
  }, [userData]);

  return (
    <div
      style={{
        background: `conic-gradient(${colorBase} 0%, ${colorBase} ${experiencePercentage}%, ${colorPorcentual} ${experiencePercentage}%, ${colorPorcentual} 100%)`,
        borderRadius: "50%",
        padding: "3px",
        display: "inline-block",
      }}
    >
      <Image
        src={`${
          userMetadata?.profile_photo_url ||
          "https://maluapp.b-cdn.net/resources/Women.png"
        }`}
        alt="userPhoto"
        style={{
          objectFit: "cover",
          borderRadius: "100%",
          width: "40px",
          height: "40px",
        }}
        width={40}
        height={40}
      />
    </div>
  );
}
