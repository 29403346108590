"use client";

import { useEffect } from "react";
import useAuthStore from "../stores/useAuthStore";
import useCartStore from "../stores/useCartStore";

export function AuthWrapper({ children }: { children: React.ReactNode }) {
  const fetchTokenAndUserData = useAuthStore(
    (state) => state.fetchTokenAndUserData
  );
  const getCart = useCartStore((state) => state.getCart);
  const { userData } = useAuthStore();

  useEffect(() => {
    fetchTokenAndUserData();
  }, [fetchTokenAndUserData]);

  useEffect(() => {
    if (userData?.id) {
      getCart();
    }
  }, [userData?.id, getCart]);

  return <>{children}</>;
}
