import Image from "next/image";
import Link from "next/link";

export default function CoachsCard({
  username,
  img_url,
  type,
  name,
}: {
  username: string;
  img_url: string;
  type: string | undefined;
  name: string;
}) {
  return (
    <div className="m-5">
      <Link
        className="flex flex-col items-center gap-10"
        href={`/coachs/${username}`}
      >
        <div className="h-40 w-40  lg:h-52 lg:w-52 p-1 bg-gradient-to-t from-orange to-bright-pink rounded-full">
          <Image
            src={img_url}
            alt="Coachs Malú Pérez"
            width={210}
            height={210}
            style={{
              borderRadius: "50%",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: "100%",
            }}
            unoptimized
          />
        </div>
        <div className="flex flex-col items-center gap-1 text-center">
          <span className="font-base text-md uppercase">{type}</span>
          <h3 className="uppercase font-bold text-xl">{name}</h3>
          <span className="font-light text-md lowercase">
            {username && `@${username}`}
          </span>
        </div>
      </Link>
    </div>
  );
}
