"use client";
import Image from "next/image";
import { AccountIcon, FavoriteIcon, HelpIcon, LogoutIcon } from "../Icons";
import Link from "next/link";
import useAuthStore from "@/stores/useAuthStore";
import { useRouter } from "next/navigation";
import axiosInstance from "@/utils/axiosInstance";
import { useState, useEffect } from "react";

export default function UserMenu(props: any) {
  const { logout, userData, userMetadata } = useAuthStore();
  const { openState } = props;
  const router = useRouter();
  const [level, setLevel] = useState(1);

  const handleLogout = async () => {
    logout();
    router.push("/");
  };

  useEffect(() => {
    if (userData?.id) {
      try {
        axiosInstance
          .get(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/users/get-level/${userData?.id}`
          )
          .then((response) => {
            setLevel(response.data.currentLevel);
          })
          .catch((error) => {
            //console.log(error);
          });
      } catch (error) {
        //console.log(error);
      }
    }
  }, [userData]);

  //console.log("userMetadata", userMetadata);

  return (
    <div
      className={`shadow flex flex-col bg-cream px-5 py-7 gap-3 rounded-md absolute right-0 top-[60px] z-50 ${
        !openState && "hidden"
      }`}
    >
      <div className="flex flex-row gap-5 items-center justify-center">
        <Image
          src={`${
            userMetadata?.profile_photo_url ||
            "https://maluapp.b-cdn.net/resources/Women.png"
          }`}
          alt="userPhoto"
          style={{
            objectFit: "cover",
            borderRadius: "100%",
            width: "50px",
            height: "50px",
          }}
          width={50}
          height={50}
        />

        <div className="flex flex-col items-start gap-0">
          <span className="text-black text-base font-normal ">
            {userMetadata?.first_name &&
            userMetadata?.last_name
              ? userMetadata?.first_name +
                " " +
                userMetadata?.last_name
              : userData?.username}
          </span>

          {level && (
            <span className="text-stone-100 text-xs font-normal bg-orange px-2 py-0 rounded-full">
              NIVEL {level}
            </span>
          )}
        </div>
      </div>

      <div className="w-[200px] h-[0px] left-[15px] top-[98px] border border-neutral-300"></div>

      <div className="flex flex-col items-start gap-3">
        <Link
          href="/my-account"
          className="flex items-center gap-3 text-black text-md font-normal"
        >
          <AccountIcon height="20px" className="fill-black" /> Mi Cuenta
        </Link>

        <Link
          href="/favorites"
          className="flex items-center gap-3 text-black text-md font-normal "
        >
          <FavoriteIcon
            height="20px"
            className="stroke-2 stroke-black fill-none"
          />
          Mis Favoritos
        </Link>

        <Link
          href="/my-account/help"
          className="flex items-center gap-3 text-black text-md font-normal "
        >
          <HelpIcon height="20px" className="fill-black" />
          Centro de ayuda
        </Link>

        <div
          className="flex items-center gap-3 text-black text-md font-normal cursor-pointer"
          onClick={handleLogout}
        >
          <LogoutIcon height="20px" className="fill-black" />
          Cerrar sesión
        </div>
      </div>
    </div>
  );
}
