"use client";

import { FavoritesProvider } from "@/providers/FavoritesProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import NavbarControl from "./NavbarControl";

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || ""
);

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <FavoritesProvider>
      <Elements stripe={stripePromise}>
        <NavbarControl />
        {children}
      </Elements>
    </FavoritesProvider>
  );
}
