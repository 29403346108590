import React from 'react';
import Image from "next/image";
import Link from "next/link";
import { MinusIcon, PlusIcon, TrashIcon } from "@/components/ui/Icons";
import useCartStore, { ProductInterface } from "@/stores/useCartStore";
import { getIp } from "@/utils/getCountryIp";
import { useState, useEffect } from "react";
import { toast } from "@/components/ui/use-toast";

interface CartItemModalProps {
  product: any & { qty: number };
}

export default function CartItemModal({ product }: CartItemModalProps) {
  const { updateCartItemQuantity, removeFromCart, error } = useCartStore();
  const [country, setCountry] = useState<string | null>(null);

  useEffect(() => {
    async function fetchCountry() {
      const countryCode = await getIp();
      setCountry(countryCode);
    }
    fetchCountry();
  }, []);

  useEffect(() => {
    if (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error,
      });
    }
  }, [error]);

  const isUSD = country !== "CL" && country !== null;

  const formatPrice = (price: number) => {
    return price.toLocaleString(isUSD ? "en-US" : "es-CL", {
      style: "currency",
      currency: isUSD ? "USD" : "CLP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const handleUpdateQuantity = async (newQuantity: number) => {
    if (newQuantity > 0) {
      await updateCartItemQuantity(product.id, newQuantity, product.variant_id);
    }
  };

  const handleRemoveItem = async () => {
    await removeFromCart(product.id, product.variant_id);
  };

  return (
    <div className="flex justify-between py-4 border-b">
      <div className="flex gap-5 items-center">
        <Link href={"/products/" + product.slug}>
          <Image
            src={product.thumbnail_url}
            alt={product.name}
            className="w-20 h-20 object-cover border border-gray-200 rounded-md"
            width={80}
            height={80}
          />
        </Link>
        <div className="flex flex-col items-start justify-between gap-2">
          <div>
            <Link href={"/products/" + product.slug}>
              <h3 className="text-lg font-semibold text-black uppercase">
                {product.name}
              </h3>
            </Link>
            {product.variant && (
              <small className="font-semibold text-gray-500 mt-2">
                {product.variant.name}: {product.variant.value}
              </small>
            )}
          </div>
          {product.type !== "subscription" && product.type !== "virtual" && (
            <div className="flex gap-2">
              <button
                className="bg-gray-200 rounded-full p-1.5"
                onClick={() => handleUpdateQuantity(product.qty - 1)}
                aria-label="Decrease quantity"
                disabled={product.qty <= 1}
              >
                <MinusIcon className="fill-black w-3 h-3" />
              </button>
              <span className="text-black">{product.qty}</span>
              <button
                className="bg-gray-200 rounded-full p-1.5"
                onClick={() => handleUpdateQuantity(product.qty + 1)}
                aria-label="Increase quantity"
              >
                <PlusIcon className="fill-black w-3 h-3" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-between items-end">
        <button
          className="bg-red-600 p-1.5 rounded-full"
          onClick={handleRemoveItem}
          aria-label="Remove item"
        >
          <TrashIcon className="fill-cream w-3 h-3" />
        </button>
        <div className="text-right">
          <span className="text-black font-medium">
            {formatPrice((isUSD ? product.discounted_usd_price ?? product.usd_price : product.discounted_price ?? product.price) * product.qty)}
          </span>
          {(isUSD ? product.discounted_usd_price : product.discounted_price) && (
            <div className="text-sm">
              <span className="text-gray-500 line-through mr-1">
                {formatPrice((isUSD ? product.usd_price : product.price) * product.qty)}
              </span>
              <span className="text-red-500">
                {(
                  ((isUSD ? product.usd_price : product.price) -
                    (isUSD ? product.discounted_usd_price! : product.discounted_price!)) /
                  (isUSD ? product.usd_price : product.price) *
                  100
                ).toFixed(0)}
                % off
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

