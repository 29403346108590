import { createContext, useContext, useEffect, useState } from "react";
import axiosInstance from "@/utils/axiosInstance";
import useAuthStore from "@/stores/useAuthStore";

interface IFavorites {
  recipe: number[];
  classes: number[];
  programs: number[];
}

interface IFavoritesContext {
  favorite: boolean;
  favorites: IFavorites;
  handleFavorite: (type: keyof IFavorites, id: number) => Promise<void>;
}

// El contexto se define con un objeto vacío de IFavorites
const FavoritesContext = createContext<IFavoritesContext>({
  favorite: false,
  favorites: { recipe: [], classes: [], programs: [] },
  handleFavorite: async (type: keyof IFavorites, id: number) => {},
});

export function FavoritesProvider({ children }: any) {
  const [favorite, setFavorite] = useState(false);

  const { userData } = useAuthStore();
  const [favorites, setFavorites] = useState({
    recipe: [],
    classes: [],
    programs: [],
  });

  const addFavorite = async (type: string, id: number) => {
    await axiosInstance
      .post(
        `${process.env.NEXT_PUBLIC_API_URL_BASE}/favorites/${userData?.id}`,
        {
          favoriteId: id,
          favoriteType: type,
        }
      )
      .then((res) => {
        //console.log(res);
        setFavorite(true);
      })
      .catch((error) => {
        //console.log(error);
        setFavorite(false);
      });
  };

  const removeFavorite = async (type: string, id: number) => {
    await axiosInstance
      .delete(
        `${process.env.NEXT_PUBLIC_API_URL_BASE}/favorites/${userData?.id}/remove/${id}/type/${type}`
      )
      .then((res) => {
        //console.log(res);
        setFavorite(false);
      })
      .catch((error) => {
        //console.log(error);
        setFavorite(true);
      });
  };

  const handleFavorite = async (type: string, id: number) => {
    if (userData?.id) {
      const isFavorite = (favorites as any)[type].includes(id);

      // Actualiza el estado antes de hacer la llamada a la API
      setFavorites((prevFavorites: any) => ({
        ...prevFavorites,
        [type]: isFavorite
          ? prevFavorites[type].filter((favId: number) => favId !== id)
          : [...prevFavorites[type], id],
      }));

      try {
        if (isFavorite) {
          await removeFavorite(type, id);
        } else {
          await addFavorite(type, id);
        }
      } catch (error) {
        // Si la llamada a la API falla, revierte el estado
        setFavorites((prevFavorites: any) => ({
          ...prevFavorites,
          [type]: isFavorite
            ? [...prevFavorites[type], id]
            : prevFavorites[type].filter((favId: number) => favId !== id),
        }));
      }
    }
  };

  useEffect(() => {
    if (userData?.id) {
      axiosInstance
        .get(
          `${process.env.NEXT_PUBLIC_API_URL_BASE}/favorites/${userData?.id}`
        )
        .then((res) => {
          //console.log(res);
          setFavorites({
            recipe: res.data.favorites.recipe || [],
            classes: res.data.favorites.classes || [],
            programs: res.data.favorites.programs || [],
          });
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  }, [userData]);

  return (
    <FavoritesContext.Provider value={{ favorite, handleFavorite, favorites }}>
      {children}
    </FavoritesContext.Provider>
  );
}

export function useFavoritesContext() {
  return useContext(FavoritesContext);
}
