"use client";
import NavbarPrivate from "@/components/ui/NavbarPrivate";
import NavbarPublic from "@/components/ui/NavbarPublic";
import useAuthStore from "@/stores/useAuthStore";

export default function NavbarControl() {
  const { userData } = useAuthStore();

  return userData?.id ? <NavbarPrivate /> : <NavbarPublic />;
}

