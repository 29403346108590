import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import axiosInstance from "@/utils/axiosInstance";
import useAuthStore from "./useAuthStore";

export interface ProductInterface {
  id: number;
  name: string;
  slug: string;
  price: number;
  usd_price: number;
  discounted_price?: number | null;
  discounted_usd_price?: number | null;
  thumbnail_url: string;
  stock: number;
  sku: string;
  type: "single" | "variant" | "subscription" | "virtual";
  category_id: number;
  grams: number;
  variant_id?: number;
}

export interface VariantInfo {
  id: number;
  name: string;
  value: string;
}

interface Coupon {
  id: number;
  code: string;
  discount: number;
  type: "percentage" | "fixed";
  applicable_products: any[];
}

interface ShippingMethod {
  id: number;
  code: string;
  title: string;
  description: string;
  price: number;
  is_selected: boolean;
}

interface CartProduct
  extends Omit<ProductInterface, "discounted_price" | "discounted_usd_price"> {
  qty: number;
  variant?: VariantInfo;
  discounted_price?: number;
  discounted_usd_price?: number;
}

interface CartState {
  cart: CartProduct[];
  isLoading: boolean;
  error: string | null;
  addToCart: (
    product: Partial<ProductInterface>,
    quantity: number,
    variant?: VariantInfo
  ) => Promise<void>;
  removeFromCart: (productId: number, variantId?: number) => Promise<void>;
  updateCartItemQuantity: (
    productId: number,
    quantity: number,
    variantId?: number
  ) => Promise<void>;
  clearCart: () => Promise<void>;
  getCart: (skipSync?: boolean) => Promise<void>;
  syncCartWithBackend: (skipGetCart?: boolean) => Promise<void>;
  coupon: Coupon | null;
  shippingMethods: ShippingMethod[];
  applyCoupon: (code: string) => Promise<void>;
  removeCoupon: () => Promise<void>;
  setShippingMethods: (methods: ShippingMethod[]) => void;
  selectShippingMethod: (methodId: number) => void;
  clearShippingMethods: () => void;
  trackCartEvent: (eventName: string, eventData?: Record<string, any>) => void;
}

const useCartStore = create<CartState>()(
  persist(
    (set, get) => ({
      cart: [],
      isLoading: false,
      error: null,
      coupon: null,
      shippingMethods: [],

      addToCart: async (product, quantity, variant) => {
        set({ isLoading: true, error: null });
        try {
          const newItem: CartProduct = {
            id: product.id!,
            name: product.name!,
            slug: product.slug!,
            price: product.price!,
            usd_price: product.usd_price!,
            discounted_price: product.discounted_price ?? undefined,
            discounted_usd_price: product.discounted_usd_price ?? undefined,
            thumbnail_url: product.thumbnail_url!,
            stock: product.stock ?? 0,
            sku: product.sku!,
            type: product.type!,
            category_id: product.category_id ?? 0,
            grams: product.grams ?? 0,
            qty: quantity,
            variant: variant,
            variant_id: variant?.id,
          };

          set((state) => ({
            cart: [...state.cart, newItem],
            lastFetched: null, // Invalidate the cache
          }));

          const { userData } = useAuthStore.getState();
          if (userData?.id) {
            await get().syncCartWithBackend();
          }

          get().trackCartEvent("cart_updated", {
            product_id: product.id,
            product_name: product.name,
            quantity: quantity,
            variant: variant ? `${variant.name}: ${variant.value}` : undefined,
            variant_id: variant?.id,
          });
          set({ isLoading: false });
        } catch (error) {
          set({ error: "Error adding item to cart", isLoading: false });
          console.error("Error adding item to cart:", error);
        }
      },

      removeFromCart: async (productId, variantId) => {
        set({ isLoading: true, error: null });
        try {
          const { userData } = useAuthStore.getState();

          // Actualizar el estado local primero para una respuesta inmediata de la UI
          set((state) => ({
            cart: state.cart.filter(
              (item) =>
                !(
                  item.id === productId &&
                  (item.variant_id === variantId ||
                    (!item.variant_id && !variantId))
                )
            ),
          }));

          // Luego, sincronizar con el backend si el usuario está autenticado
          if (userData?.id) {
            await axiosInstance.delete(`/cart/${userData.id}/${productId}`, {
              data: { variant_id: variantId },
            });
          }

          get().trackCartEvent("cart_updated", {
            product_id: productId,
            quantity: 0,
            variant: variantId ? "removed" : undefined,
          });
          set({ isLoading: false });
        } catch (error) {
          console.error("Error removing item from cart:", error);
          // En caso de error, volvemos a obtener el carrito del backend para asegurar la consistencia
          await get().getCart();
          set({ error: "Error removing item from cart", isLoading: false });
        }
      },

      updateCartItemQuantity: async (productId, quantity, variantId) => {
        set({ isLoading: true, error: null });
        try {
          const { userData } = useAuthStore.getState();

          if (userData?.id) {
            try {
              const response = await axiosInstance.put(
                `/cart/${userData.id}/${productId}`,
                {
                  quantity,
                  variant_id: variantId,
                }
              );

              // Update cart state if successful
              set((state) => ({
                cart: state.cart.map((item) =>
                  item.id === productId &&
                  (item.variant_id === variantId ||
                    (!item.variant_id && !variantId))
                    ? { ...item, qty: quantity }
                    : item
                ),
              }));

              get().trackCartEvent("cart_updated", {
                product_id: productId,
                quantity: quantity,
                variant: variantId ? "updated" : undefined,
              });
            } catch (error: any) {
              // Handle axios error response
              if (error.response && error.response.status === 400) {
                const { availableStock } = error.response.data;
                if (availableStock !== undefined) {
                  // Update cart with available stock quantity
                  set((state) => ({
                    cart: state.cart.map((item) =>
                      item.id === productId &&
                      (item.variant_id === variantId ||
                        (!item.variant_id && !variantId))
                        ? {
                            ...item,
                            qty: availableStock,
                            stock: availableStock,
                          }
                        : item
                    ),
                    error: `Solo ${availableStock} productos disponibles.`,
                  }));
                } else {
                  throw new Error(
                    error.response.data.message || "Error updating cart item"
                  );
                }
              } else {
                throw error;
              }
            }
          }
        } catch (error: any) {
          console.error("Error updating cart item:", error);
          set({
            error: error.message || "Error updating cart item",
            isLoading: false,
          });
        } finally {
          set({ isLoading: false });
        }
      },

      clearCart: async () => {
        set({ isLoading: true, error: null });
        try {
          const { userData } = useAuthStore.getState();

          set({ cart: [], coupon: null, shippingMethods: [] });

          if (userData?.id) {
            await axiosInstance.delete(`/cart/clear/${userData.id}`);
          }

          get().trackCartEvent("cart_deleted");
          set({ isLoading: false });
        } catch (error) {
          set({ error: "Error clearing cart", isLoading: false });
          console.error("Error clearing cart:", error);
        }
      },

      getCart: async () => {
        set({ isLoading: true, error: null });
        try {
          const { userData } = useAuthStore.getState();
          if (userData?.id) {
            const localCart = get().cart;

            // If there are items in the local cart, sync them with the backend first
            if (localCart.length > 0) {
              await get().syncCartWithBackend();
            }

            const response = await axiosInstance.get(`/cart/${userData.id}`);
            console.log(response);

            if (
              response.data.cartItems &&
              Array.isArray(response.data.cartItems) &&
              response.data.cartItems.length > 0
            ) {
              const transformedCart: CartProduct[] =
                response.data.cartItems.map((item: any) => {
                  const baseProduct = {
                    id: item.product_id,
                    name: item.product.name,
                    slug: item.product.slug,
                    price: Number(item.price),
                    usd_price: Number(item.usd_price),
                    discounted_price: item.discounted_price
                      ? Number(item.discounted_price)
                      : undefined,
                    discounted_usd_price: item.discounted_usd_price
                      ? Number(item.discounted_usd_price)
                      : undefined,
                    thumbnail_url:
                      item.product_type === "variant"
                        ? item.product_variation_detail.thumbnail_url
                        : item.product.thumbnail_url ?? "",
                    stock:
                      item.product_type === "subscription"
                        ? item.product.stock
                        : item.product_variation_detail?.stock ?? 0,
                    sku: item.product.sku || "",
                    type: item.product_type,
                    category_id: item.product.category_id || 0,
                    grams: item.product.grams || 0,
                    qty: item.quantity,
                  };

                  if (
                    item.product_type === "variant" &&
                    item.product_variation_detail
                  ) {
                    return {
                      ...baseProduct,
                      variant: {
                        id: item.product_variation_detail.option.id,
                        name: item.product_variation_detail.option.variation
                          .name,
                        value: item.product_variation_detail.option.value,
                        hex_code: item.product_variation_detail.option.hex_code,
                      },
                      variant_id: item.variant_id,
                      price: Number(item.product_variation_detail.price),
                      usd_price: Number(
                        item.product_variation_detail.usd_price
                      ),
                      discounted_price: item.product_variation_detail
                        .discounted_price
                        ? Number(item.product_variation_detail.discounted_price)
                        : undefined,
                      discounted_usd_price: item.product_variation_detail
                        .discounted_usd_price
                        ? Number(
                            item.product_variation_detail.discounted_usd_price
                          )
                        : undefined,
                    };
                  }

                  return baseProduct;
                });

              set({
                cart: transformedCart,
                coupon: response.data.summary?.coupon
                  ? {
                      id: response.data.summary.coupon.id || 0,
                      code: response.data.summary.coupon.code,
                      discount: response.data.summary.coupon.discountAmount,
                      type: response.data.summary.coupon.discountType,
                      applicable_products:
                        response.data.summary.coupon.applicable_products,
                    }
                  : undefined,
                isLoading: false,
              });
            } else {
              // If the backend cart is empty, keep the local cart
              set({ isLoading: false });
            }
          } else {
            set({ isLoading: false });
          }
        } catch (error) {
          console.error("Error fetching cart:", error);
          set({ error: "Error fetching cart", isLoading: false });
        }
      },

      syncCartWithBackend: async () => {
        const { userData } = useAuthStore.getState();
        if (userData?.id) {
          try {
            set({ isLoading: true, error: null });
            const localCart = get().cart;
            const localCoupon = localStorage.getItem("guestCoupon");

            const cartItems = localCart.map((item) => ({
              product_id: item.id,
              quantity: item.qty,
              variant_id: item.variant_id,
            }));

            const response = await axiosInstance.post("/cart", {
              user_id: userData.id,
              products: cartItems,
            });

            if (response.data && response.data.success) {
              const transformedCart = response.data.cart.map((item: any) => ({
                id: item.id,
                name: item.name,
                slug: item.slug,
                price: Number(item.price),
                usd_price: Number(item.usd_price),
                discounted_price: item.discounted_price
                  ? Number(item.discounted_price)
                  : undefined,
                discounted_usd_price: item.discounted_usd_price
                  ? Number(item.discounted_usd_price)
                  : undefined,
                thumbnail_url: item.thumbnail_url,
                stock: item.stock,
                sku: item.sku,
                type: item.type,
                category_id: item.category_id,
                grams: item.grams,
                qty: item.qty,
                variant_id: item.variant_id,
              }));

              set({ cart: transformedCart });

              // If there's a local coupon, apply it to the backend
              if (localCoupon) {
                const couponData = JSON.parse(localCoupon);
                await axiosInstance.post(`/cart/apply-coupon/${userData.id}`, {
                  code: couponData.code,
                });
                localStorage.removeItem("guestCoupon");
              }

              set({ isLoading: false });
            } else {
              console.error(
                "Cart sync failed:",
                response.data.error || "Unknown error"
              );
              set({ isLoading: false });
            }
          } catch (error) {
            console.error("Error syncing cart with backend:", error);
            set({
              isLoading: false,
              error: "Failed to sync cart with backend",
            });
          }
        }
      },

      applyCoupon: async (code: string) => {
        set({ isLoading: true, error: null });
        try {
          const { userData } = useAuthStore.getState();
          const cart = get().cart;
          const cartItems = cart.map((item) => ({ id: item.id }));

          const response = await axiosInstance.post(
            `${process.env.NEXT_PUBLIC_API_URL_BASE}/coupons/${code}`,
            { cartItems }
          );

          console.log(response);

          if (response.data && response.data.id) {
            const couponData: Coupon = {
              id: response.data.id,
              code: response.data.code,
              discount: Number(response.data.discount_amount),
              type: response.data.discount_type as "percentage" | "fixed",
              applicable_products: response.data.applicable_products,
            };

            set({ coupon: couponData });

            if (userData?.id) {
              // Si el usuario está autenticado, sincronizar con el backend
              await axiosInstance.post(`/cart/apply-coupon/${userData.id}`, {
                code,
              });
            } else {
              // Si es un usuario invitado, almacenar en localStorage
              localStorage.setItem("guestCoupon", JSON.stringify(couponData));
            }
          } else {
            console.log("entro al elsee");
            throw new Error(response.data.message || "Invalid coupon");
          }
          set({ isLoading: false });
        } catch (error) {
          set({ error: "Error applying coupon", isLoading: false });
          throw error;
        }
      },
      checkGuestCoupon: () => {
        const localCoupon = localStorage.getItem("guestCoupon");
        if (localCoupon) {
          const couponData = JSON.parse(localCoupon);
          set({ coupon: couponData });
        }
      },

      removeCoupon: async () => {
        set({ isLoading: true, error: null });
        try {
          const { userData } = useAuthStore.getState();
          if (userData?.id) {
            await axiosInstance.delete(`/cart/remove-coupon/${userData.id}`);
          }
          set({ coupon: null, isLoading: false });
        } catch (error) {
          //console.error('Error removing coupon:', error);
          set({ error: "Error removing coupon", isLoading: false });
        }
      },

      setShippingMethods: (methods) => {
        set({ shippingMethods: methods });
      },

      selectShippingMethod: (methodId) =>
        set((state) => {
          const newMethods = state.shippingMethods.map((method) => ({
            ...method,
            is_selected: method.id === methodId,
          }));

          return { shippingMethods: newMethods };
        }),

      clearShippingMethods: () => {
        set({ shippingMethods: [] });
      },

      trackCartEvent: (eventName: string, eventData?: Record<string, any>) => {
        if (typeof window !== "undefined" && (window as any).trackBrevoEvent) {
          const cartData = get().cart.map((item) => ({
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.qty,
            variant: item.variant
              ? `${item.variant.name}: ${item.variant.value}`
              : undefined,
            total: item.price * item.qty,
          }));

          const cartTotal = cartData.reduce((sum, item) => sum + item.total, 0);

          const couponData = get().coupon
            ? {
                coupon_code: get().coupon?.code,
                discount_amount: get().coupon?.discount,
              }
            : undefined;

          const eventPayload = {
            cart_items: JSON.stringify(cartData),
            cart_total: cartTotal,
            coupon: couponData ? JSON.stringify(couponData) : undefined,
            ...eventData,
          };

          (window as any).trackBrevoEvent(eventName, eventPayload);
        }
      },
    }),
    {
      name: "cart-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useCartStore;
